/*
 * @Description:: 请补充填写
 * @Version: 1.0
 * @Author: smallWhite
 * @Date: 2024-07-18 08:53:17
 * @LastEditors: 小白
 * @LastEditTime: 2024-09-06 10:34:19
 * @FilePath: \aichatadmin\src\router\index.ts
 */
import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: () => import("@/views/home/home.vue")
  },
  {
    path: '/chat',
    name: 'chat',
    component: () => import("@/views/chat/chat.vue")
  },
  {
    path: '/gopage',
    name: 'gopage',
    component: () => import("@/views/gopage/index.vue")
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
