<!--
 * @Description:: 请补充填写
 * @Version: 1.0
 * @Author: smallWhite
 * @Date: 2024-08-22 17:09:47
 * @LastEditors: 小白
 * @LastEditTime: 2024-09-10 17:31:40
 * @FilePath: \aichatadmin\src\App.vue
-->
<template>
  <router-view
    v-slot="{ Component, route }">
    <transition
      @before-enter="beforeEnter"
      @enter-active="enterActive"
      @leave-active="leaveActive"
      mode="out-in">
      <component
        :is="Component"
        :key="route.path" />
    </transition>
  </router-view>
</template>
  <script setup>
import 'animate.css'
import { onMounted } from 'vue'
import { useRoute } from 'vue-router'
const route = useRoute()
// 页面进入前的钩子函数
const beforeEnter = el => {
  // 在元素上添加 animate.css 的类名，使其具有进入动画效果
  el.classList.add('animate__animated', 'animate__fadeIn')
}
onMounted(() => {
  var test = window.location.href
  const detectDeviceType = () => (/Android|webOS|iPhone|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ? '1' : '2')
  if (detectDeviceType() === '1') {
    window.location.href = 'https://m.daovic.com'
  }
})
// 页面进入时的钩子函数
const enterActive = el => {
  // 当进入动画开始时，移除 'animate__fadeInLeft' 类名
  // 这样可以确保元素在进入时应用动画效果，而不会重复添加
  el.classList.remove('animate__fadeIn')
}

// 页面离开时的钩子函数
const leaveActive = el => {
  // 在元素上添加 animate.css 的类名，使其具有离开动画效果
  el.classList.add('animate__animated', 'animate__fadeOut')
  // 当动画结束时，移除添加的动画类名
  el.addEventListener(
    'animationend',
    () => {
      el.classList.remove('animate__animated', 'animate__fadeOut')
    },
    { once: true }
  ) // 只监听一次动画结束事件
}
</script>