/*
 * @Description:: 请补充填写
 * @Version: 1.0
 * @Author: smallWhite
 * @Date: 2024-07-18 08:53:17
 * @LastEditors: smallWhite
 * @LastEditTime: 2024-08-26 22:31:44
 * @FilePath: /aichatadmin/src/store/index.ts
 */
import { createStore } from 'vuex'
import createPersistedState from 'vuex-persistedstate'
export default createStore({
  state: {
    token: '',
    useId: '',
    userName:'',
    userType:"",
    logId:'',
    message:'',
    messageList:[],
    isConnected: false,//连接状态
  },
  getters: {
   
  },
  mutations: {
    setConnected(state: any, isConnected: boolean) {
      state.isConnected = isConnected
    },
    setMessageList(state:any,msgList:any) {
      state.messageList = msgList
    },
    setMsg(state:any,msg:any) {
      state.message = msg
    },
    setToken: (state:any,token:any) => state.token = token,
    setUserId: (state:any,useId:any) => state.useId = useId,
    setUserType: (state:any,userType:any) => state.userType = userType,
    setUserName: (state:any,userName:any) => state.userName = userName,
    setLogId: (state:any,logId:any) => state.logId = logId,
  },
  actions: {
    connect({ commit }: { commit: any }) {
      // 连接成功后，将 isConnected 状态设置为 true
      commit('setConnected', true)
    },
    disconnect({ commit }: { commit: any }) {
      // 断开连接或退出登录时，将 isConnected 状态设置为 false
      commit('setConnected', false)
    }
  },
  modules: {
  },
  plugins: [createPersistedState({
    storage: window.localStorage, // 或者 localStorage
  })]
})

