/*
 * @Description:: 请补充填写
 * @Version: 1.0
 * @Author: smallWhite
 * @Date: 2024-07-18 08:53:17
 * @LastEditors: smallWhite
 * @LastEditTime: 2024-08-28 08:52:41
 * @FilePath: /aichatadmin/src/main.ts
 */
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'animate.css';
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import 'animate.css'
const app = createApp(App)
app.use(store)
app.use(router)
app.use(ElementPlus)
app.mount('#app')